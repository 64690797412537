import { cn } from '../../utils/cn';

export const ToolsIcon = (props: { className?: string }): JSX.Element => {
  return (
    <svg
      className={cn(`w-4 h-4 fill-white`, props.className)}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='1.14258' y='1.14285' width='5.71429' height='5.71429' rx='1' />
      <rect x='9.14258' y='1.14285' width='5.71428' height='5.71429' rx='1' />
      <rect x='1.14258' y='9.14285' width='5.71429' height='5.71428' rx='1' />
      <rect x='9.14258' y='9.14285' width='5.71428' height='5.71428' rx='1' />
    </svg>
  );
};
